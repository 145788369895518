<template>
  <div v-if="init" class="popups">
    <div class="popups-push-notifications-off popups-section">
      <div class="popups-info-area">
        <h3>Push notifications off</h3>
        <p>These users have not allowed notifications in app.</p>
        <p>
          Users with notifications off:
          <b
            >{{ activeAnalyticsPushNotificationsDisabledCount }} /
            {{ activeAnalyticsSignedInDocs.length }}</b
          >
        </p>

        <button @click="triggerPushDisabledPopup">Trigger popup</button>
      </div>
      <div class="popups-log">
        <div
          class="popups-log-entry"
          v-for="(popup, index) in pushDisabledPopups"
          :key="popup.id"
          @click="deletePushDisabledPopup(popup)"
        >
          <div class="popup-log-header">
            <span v-if="pushDisabledPopupsActive && index === 0" class="success"
              >Ending</span
            >
            <span class="danger" v-else>Finished</span>
            {{ popup.endTime.toDate() | moment("from", "now") }}
          </div>
          <div>
            Sent to: <span>{{ popup.startCount }}</span>
          </div>
          <div v-if="popup.endCount !== false">
            Opened:<span>{{ popup.openCount }}</span>
          </div>
          <div v-if="popup.endCount !== false">
            Enabled:
            <span>{{ popup.startCount - popup.endCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="popups-push-notifications-reminder popups-section">
      <div class="popups-info-area">
        <h3>Push notifications reminder</h3>
        <p>
          Triggers a reminder popup for users who has not activated all push
          notification options.<br /><br />
          Showing users who have logged in, push notifications are on and not
          all push notifications are enabled.
        </p>
        <h4>Current stats:</h4>
        <p>
          Eligible users: <b> {{ pushNotificationsReminderUsers.length }}</b>
        </p>

        <h4>Off counts:</h4>
        <p style="margin-bottom: 4px">
          Chat:
          <b>{{ pushNotificationsDisabledCount.chat }}</b>
        </p>
        <p style="margin-bottom: 4px">
          News & Offers:
          <b>{{ pushNotificationsDisabledCount.newsAndOffers }}</b>
        </p>
        <p style="margin-bottom: 4px">
          Incoming delivery:
          <b>{{ pushNotificationsDisabledCount.deliveryInfo }}</b>
        </p>
        <br />
        <button @click="triggerPushReminderPopup">Trigger popup</button>
      </div>
      <div class="popups-log">
        <div
          class="popups-log-entry"
          v-for="(popup, index) in pushReminderPopups"
          :key="popup.id"
          @click="deletePushReminderPopup(popup)"
        >
          <div class="popup-log-header">
            <span v-if="pushReminderPopupsActive && index === 0" class="success"
              >Ending</span
            >
            <span class="danger" v-else>Finished</span>
            {{ popup.endTime.toDate() | moment("from", "now") }}
          </div>
          <div>
            Sent to: <span>{{ popup.startCount }}</span>
          </div>
          <div v-if="popup.endStats !== false">
            Opened:<span>{{ popup.openCount }}</span>
          </div>
          <div v-if="popup.endStats !== false">
            <div v-for="(count, key) in popup.endStats" :key="popup.id + key">
              {{ key }}: <span>{{ popup.startStats[key] - count }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "Popups",
  data() {
    return {
      loading: false,
    };
  },
  mounted() {},
  async created() {
    this.$store.commit("popups/setInit", true);
    console.log(this.pushDisabledPopupsActive);
  },
  computed: {
    ...mapState({
      init: (state) => state.popups.init,
      userList: (state) => state.users.userList,
      appSettings: (state) => state.settings.appSettings,
      userAnalytics: (state) => state.analytics.analyticsDocs,
    }),
    ...mapGetters("settings", ["notificationsOffPopups"]),
    ...mapGetters("analytics", [
      "analyticsDocById",
      "activeAnalyticsPushNotificationsDisabledCount",
      "activeAnalyticsSignedInPushNotificationsEnabledDocs",
      "activeAnalyticsSignedInDocs",
    ]),
    ...mapGetters("popups", [
      "pushDisabledPopups",
      "pushReminderPopups",
      "pushDisabledPopupsActive",
      "pushReminderPopupsActive",
      "pushNotificationsReminderUsers",
      "pushNotificationsDisabledCount",
    ]),
  },
  methods: {
    deletePushDisabledPopup(popup) {
      let confirm = window.confirm("Delete popup?");
      if (confirm) {
        this.$store.dispatch("popups/deletePushDisabledPopup", popup);
      }
    },
    deletePushReminderPopup(popup) {
      let confirm = window.confirm("Delete popup?");
      if (confirm) {
        this.$store.dispatch("popups/deletePushReminderPopup", popup);
      }
    },
    triggerPushDisabledPopup() {
      this.$store.dispatch("popups/triggerPushDisabledPopup");
    },
    triggerPushReminderPopup() {
      this.$store.dispatch("popups/triggerPushReminderPopup");
    },
  },
};
</script>
